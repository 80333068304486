import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Home.css';

const Home = () => {
  useEffect(() => {
    const titleWords = [
      { text: "Descopera", delay: 0 },
      { text: "viitorul", delay: 0.6, highlight: true },
      { text: "Comunicarii", delay: 0.4 },
      // { text: "Future", delay: 0.6, highlight: true },
      // { text: "of", delay: 0.8 },
      // { text: "Communication", delay: 1.0, highlight: true },
      // { text: "by", delay: 1.2 },
      // { text: "PHONUS", delay: 1.4, highlight: true }
    ];
    
    const titleElement = document.querySelector('.animated-title');
    if (titleElement) {
      titleElement.innerHTML = titleWords
        .map((word) => 
          `<span class="word ${word.highlight ? 'highlight' : 'normal'}" 
            style="--delay: ${word.delay}s">
            ${word.text}
          </span>`
        )
        .join(' ');
    }
  }, []);

  return (
    <div id="home" className="home">
      <div className="spline-background" style={{ zIndex: -1 }}>
  <iframe
    src="https://my.spline.design/nexbotrobotcharacterconcept-a601d7f512f9f4c1810ecf9e1bc1c093/"
    frameBorder="0"
    style={{ zIndex: -1 }}
    title="Spline Design"
  ></iframe>
</div>
      
      <Container fluid className="p-0">
        <Row className="hero m-0">
          <Col className="text-container-wrapper">
            <div className="text-container animate-on-scroll">
              <h1 className="animated-title">Descopera viitorul Comunicarii</h1>
              <p className="animated-text">Specialiști în comercializarea unei game variate de produse audio, video, data-media și accesorii.</p>

            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default Home;