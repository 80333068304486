import React from 'react';
import './Services.css';

const Services = () => {
  const services = [
    {
      id: 1,
      icon: 'fas fa-headphones',
      titleRo: 'Produse Audio',
      titleEn: 'Audio Products',
      descRo: 'Echipamente audio de înaltă calitate și accesorii pentru profesioniști și pasionați',
      descEn: 'High-quality audio equipment and accessories for professionals and enthusiasts',
      color: '#6b4bff'
    },
    {
      id: 2,
      icon: 'fas fa-video',
      titleRo: 'Sisteme Video',
      titleEn: 'Video Systems',
      descRo: 'Soluții video profesionale și echipamente de ultimă generație',
      descEn: 'Professional video solutions and cutting-edge equipment',
      color: '#4b8fff'
    },
    {
      id: 3,
      icon: 'fas fa-database',
      titleRo: 'Data-Media',
      titleEn: 'Data-Media',
      descRo: 'Produse și servicii pentru stocarea și gestionarea datelor media',
      descEn: 'Products and services for media data storage and management',
      color: '#ff4b8f'
    }
  ];

  React.useEffect(() => {
    const cards = document.querySelectorAll('.service-card');
    
    const handleMouseMove = (e, card) => {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      card.style.setProperty('--mouse-x', `${x}px`);
      card.style.setProperty('--mouse-y', `${y}px`);
    };

    cards.forEach(card => {
      card.addEventListener('mousemove', (e) => handleMouseMove(e, card));
    });

    return () => {
      cards.forEach(card => {
        card.removeEventListener('mousemove', handleMouseMove);
      });
    };
  }, []);

  return (
    <div id="services" className="services-container">
      <div className="services-header fade-in">
        <h1>Serviciile noastre</h1>
        <div className="header-line"></div>
      </div>
      
      <div className="services-grid">
        {services.map((service, index) => (
          <div 
            className="service-card" 
            key={service.id}
            style={{'--delay': `${index * 0.2}s`}}
          >
            <div className="service-icon">
              <i className={service.icon} style={{color: service.color}}></i>
            </div>
            <div className="service-content">
              <h2>{service.titleRo}</h2>
              <h3>{service.titleEn}</h3>
              <p className="ro">{service.descRo}</p>
              <p className="en">{service.descEn}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;