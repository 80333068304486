import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE PHONUS SRL.pdf";

const Footer = () => {
  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col lg={4} md={12} className="footer-logo-section">
            <img
              src="/images/phonusLogo2.png"
              alt="Phonus Logo"
              className="footer-logo"
            />
          </Col>

          <Col lg={4} md={12} className="footer-social">
            {/* <div className="social-links">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://wa.me/yourwhatsappnumber" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-instagram"></i>
              </a>
            </div> */}
          </Col>

          <Col lg={4} md={12} className="footer-text">
            <p>Phonus - Soluții experte în telecomunicații</p>
          </Col>
        </Row>
      </Container>

      

      <div className="footer-banner">
        <div className="logo-row">
          <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
          <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
          <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
          <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
        </div>
        <button onClick={handleInfoClick} className="info-button">
          Proiect digitalizare - Află mai multe
        </button>
      </div>

      <div className="web-links">
        <div className="url-links">
          <a href="http://www.regionordest.ro" className="web-link">
            www.regionordest.ro
          </a>
          <a
            href="https://www.facebook.com/Regio.NordEst.ro"
            className="web-link"
          >
            www.facebook.com/Regio.NordEst.ro
          </a>
        </div>
        <div className="aproape-button">Aproape de tine</div>
      </div>
    </footer>
  );
};

export default Footer;
