import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Home from './components/Home';
import Services from './components/Services';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contact from './components/Contact';
import ChatSystem from './components/ChatSystem';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <Services />
      <Contact />
      <Footer />
      <ChatSystem />
    </div>
  );
}

export default App;