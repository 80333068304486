import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, X, Send, Settings } from 'lucide-react';
import './ChatSystem.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;

if (!PROJECT_NAME) {
  console.error('PROJECT_NAME environment variable is required');
}

const ChatSystem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [aiInstructions, setAiInstructions] = useState('');
  const messagesEndRef = useRef(null);

  // Check for admin token
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const adminToken = urlParams.get('adminToken');
    if (adminToken === process.env.REACT_APP_CHAT_ADMIN_TOKEN) {
      setIsAdmin(true);
      fetchInstructions(adminToken);
    }
  }, []);

  const fetchInstructions = async (adminToken) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/${PROJECT_NAME}/instructions`, {
        headers: {
          'X-Admin-Token': adminToken
        }
      });
      if (response.ok) {
        const data = await response.json();
        setAiInstructions(data.instructions);
      }
    } catch (error) {
      console.error('Error fetching instructions:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (!inputText.trim() || isLoading) return;

    try {
      setIsLoading(true);
      const userMessage = {
        text: inputText,
        sender: 'user',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, userMessage]);
      setInputText('');

      const conversationHistory = messages.map(msg => ({
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text
      }));

      const response = await fetch(`${API_BASE_URL}/api/${PROJECT_NAME}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          messages: [
            ...conversationHistory,
            { role: 'user', content: inputText }
          ]
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get AI response');
      }

      const data = await response.json();
      const aiMessage = {
        text: data.message,
        sender: 'ai',
        timestamp: data.timestamp
      };

      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        text: "I apologize, but I encountered an error. Please try again or contact our support team.",
        sender: 'ai',
        timestamp: new Date().toISOString()
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstructionsUpdate = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const adminToken = urlParams.get('adminToken');
      
      const response = await fetch(`${API_BASE_URL}/api/${PROJECT_NAME}/instructions`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Admin-Token': adminToken
        },
        body: JSON.stringify({ instructions: aiInstructions })
      });

      if (response.ok) {
        setShowInstructions(false);
        alert('Instructions updated successfully!');
      } else {
        throw new Error('Failed to update instructions');
      }
    } catch (error) {
      console.error('Error saving instructions:', error);
      alert('Failed to update instructions');
    }
  };

  return (
    <div className="chat-overlay">
      {isOpen && (
        <div className={`chat-window ${isOpen ? 'open' : 'closed'}`}>
          <div className="chat-header">
            <h3 className="chat-header-title">Chat Support</h3>
            <div className="chat-header-actions">
              {isAdmin && (
                <button
                  className="chat-header-button"
                  onClick={() => setShowInstructions(!showInstructions)}
                >
                  <Settings size={20} />
                </button>
              )}
              <button 
                className="chat-header-button"
                onClick={() => setIsOpen(false)}
              >
                <X size={20} />
              </button>
            </div>
          </div>

          {isAdmin && showInstructions && (
            <div className="admin-panel">
              <h4>Edit AI Instructions</h4>
              <textarea
                value={aiInstructions}
                onChange={(e) => setAiInstructions(e.target.value)}
                placeholder="Enter AI instructions..."
              />
              <button onClick={handleInstructionsUpdate}>
                Update Instructions
              </button>
            </div>
          )}

          <div className="chat-messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message-bubble ${
                  message.sender === 'user' ? 'message-user' : 'message-ai'
                }`}
              >
                {message.text}
              </div>
            ))}
            {isLoading && (
              <div className="typing-indicator">
                <div className="typing-dot"></div>
                <div className="typing-dot"></div>
                <div className="typing-dot"></div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <div className="chat-input-container">
            <div className="chat-input-wrapper">
              <input
                type="text"
                className="chat-input"
                placeholder="Type your message..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                disabled={isLoading}
              />
              <button
                className="chat-send-button"
                onClick={handleSend}
                disabled={isLoading || !inputText.trim()}
              >
                <Send size={18} />
              </button>
            </div>
          </div>
        </div>
      )}

      <button 
        className="chat-toggle-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <MessageCircle />
      </button>
    </div>
  );
};

export default ChatSystem;