import React, { useState } from 'react';
import { Navbar as BootstrapNavbar, Container, Nav, Button } from 'react-bootstrap';
import './Navbar.css';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setExpanded(false);
  };

  return (
    <BootstrapNavbar 
      expand="lg" 
      variant="dark" 
      fixed="top" 
      className="custom-navbar"
      expanded={expanded}
      onToggle={(expanded) => setExpanded(expanded)}
    >
      <Container className="navbar-container">
        <BootstrapNavbar.Brand 
          onClick={() => {
            scrollToSection('home');
            setExpanded(false);
          }} 
          style={{ cursor: 'pointer' }}
        >
          <img
            src="/images/phonusLogo2.png"
            alt="TeleCorp Logo"
            className="navbar-logo"
            height="100"
          />
        </BootstrapNavbar.Brand>
        
        <BootstrapNavbar.Toggle 
          aria-controls="basic-navbar-nav"
          className="custom-toggler"
        />
        
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-links-center">
            <Nav.Link onClick={() => scrollToSection('home')}>Acasă</Nav.Link>
            <Nav.Link onClick={() => scrollToSection('services')}>Servicii</Nav.Link>
            <Nav.Link onClick={() => scrollToSection('contact')}>Contact</Nav.Link>
          </Nav>
          <Button 
            className="login-button" 
            onClick={() => window.location.href = 'https://dashboard.echipamente-it.com'}
          >
            Login
          </Button>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;