import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Contact.css';

const Contact = () => {
  return (
    <div id="contact" className="contact-section">
      <Container>
        <h2 className="section-title">Contact</h2>
        
        {/* Contact Info Row */}
        <Row className="contact-info-row">
          <Col lg={4}>
            <div className="info-card">
              <h3>Email</h3>
              <a href="mailto:phonus99@yahoo.com" className="contact-link">
                phonus99@yahoo.com
              </a>
            </div>
          </Col>
          <Col lg={4}>
            <div className="info-card">
              <h3>Telefon</h3>
              <a href="tel:+40722641503" className="contact-link">
                +40722641503
              </a>
            </div>
          </Col>
          <Col lg={4}>
            <div className="info-card">
              <h3>Social Media</h3>
              <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=61569443284051" target="https://www.facebook.com/profile.php?id=61569443284051" rel="noopener noreferrer" className="contact-link">
                  Facebook
                </a>
                <span className="link-separator"> | </span>
                <a href="https://www.linkedin.com/in/phonus-echipamente-it-7a6b6733a/" target="https://www.linkedin.com/in/phonus-echipamente-it-7a6b6733a/" rel="noopener noreferrer" className="contact-link">
                  LinkedIn
                </a>
              </div>
            </div>
          </Col>
        </Row>

        {/* Maps Row */}
        <Row className="contact-content">
          <Col lg={6} className="contact-info">
            <div className="location-card">
              <h3>Birou/Depozit Iași</h3>
              <p>str. Aleea NICOLINA, nr. 5, bl. Z4, sc. B, ap. 2, Aleea NICOLINA, NR. 5, BL. Z4, SC. B, AP. 2</p>
              <div className="map-container">
                <iframe
                  title="Bucharest Office Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.000000000000!2d27.000000!3d47.000000!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2sJud.%20IASI%2C%20loc.%20IASI%2C%20str.%20Aleea%20NICOLINA%2C%20nr.%205%2C%20bl.%20Z4%2C%20sc.%20B%2C%20ap.%202!5e0!3m2!1sen!2sro!4v1690000000000!5m2!1sen!2sro"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </Col>
          <Col lg={6} className="contact-info">
            <div className="location-card">
              <h3>Sediu Iași</h3>
              <p>str. Aleea MESTECENILOR, nr. 8, Aleea MESTECENILOR, NR. 8</p>
              <div className="map-container">
                <iframe
                  title="Iasi Office Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.5449438012195!2d27.568000!3d47.156500!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafbf100000000%3A0x0!2sJud.%20IASI%2C%20loc.%20IASI%2C%20str.%20Aleea%20MESTECENILOR%2C%20nr.%208!5e0!3m2!1sen!2sro!4v1690000000000!5m2!1sen!2sro"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;